import React from 'react'
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image"
import ContactDetails from './ContactDetails'
export default function BannerMain({ logoImage }) {
    return (
        <>
            <Container className="pt-6">
                <section id="info">
                    <div className="row">
                        <div className="col-md-8 pt-3">
                            <GatsbyImage image={logoImage} alt="shanti-aid" />
                        </div>
                        <div className="col-md-4">
                            <ContactDetails />
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}

