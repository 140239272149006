import React from "react"
import { Container } from "react-bootstrap"

export default function Funding() {
  return (
    <>
    
    <Container>
      <div className="justify-content-center">
        <div className="align-self-center text-center pb-5">
            <a className="text-decoration-none" href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate">
              <div className="pb-3">
                <h2>£10</h2>
                <strong>Your gift of £10 can help provide a couple of meals for a victim
                  of domestic violence, who has fled her home with no money or any
                  provisions.</strong>
              </div>
            </a>
            <a className="text-decoration-none" href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate">
              <div className="pb-3">
                <h2>£25</h2>
                <strong>Your gift of £25 can help provide an essential
                  “restart pack” to help a family or woman to get through the first
                  couple of days when fleeing from their home. This would contain
                  basic toiletries and long-life basic essential foods.</strong>
              </div>
            </a>
            <a className="text-decoration-none" href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate">
              <div className="pb-3">
                <h2>£45</h2>
                <strong>Your gift of £45 can help provide a support worker for a
                  family or domestic violence victim at first point of contact. To
                  reassure, engage and provide support straight away when they need it
                  the most.</strong>
              </div>
            </a>

            <a className="text-decoration-none" href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate">
              <div className="pb-5">
                <strong>Or just donate what you can.</strong>
              </div>
            </a>

        <h2><i>Empower us to Empower Women</i></h2>
        </div>
      </div>
    </Container>
    </>
  )
}
