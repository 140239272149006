import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "react-bootstrap";

export default function FundRaising({ eflogo, gofundme, smile }) {
    return (
        <Container>
            <div className="row">
                <div className="col-md-6 d-flex justify-content-center pt-5">
                    <a href="https://www.easyfundraising.org.uk/causes/shantiaid/?invite=VSR43S&referral-campaign=c2s" target="_blank" rel="noreferrer">
                        <GatsbyImage image={eflogo} alt="easy-fundraising" />
                    </a>
                </div>
                <div className="ml-5 col-md-6 d-flex justify-content-center pt-5">
                    <a href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate?utm_source=widget&utm_medium=referral&utm_campaign=p_cp+share-sheet" target="_blank" rel="noreferrer">
                        <GatsbyImage image={gofundme} alt="gofundme" />
                    </a>
                </div>
                {/* <div className="ml-5 col-md-4 d-flex justify-content-center pt-5 pb-5">
                    <a href="https://smile.amazon.co.uk/ch/1194862-0" target="_blank" rel="noreferrer">
                        <GatsbyImage image={smile} alt="gofundme" />
                    </a>
                </div> */}
            </div>
        </Container>
    )
}
