import React from "react"
import { Container } from "react-bootstrap"
import NewTraining from "../images/new-training.png"
export default function Strapline() {
  return (
    <>
      <Container>
        <div className="justify-content-center">
          <div className="align-self-center text-center ">
            <h1 className="pt-0">Mission Statement</h1>
            <h5 className="pb-5">
              At Shanti Aid, we want to Empower Women to have an Independent and Confident Life.
              We will take time to listen to you, guide you and support you through your journey.
            </h5>

            <h5 className="pb-0">
              Working with <i>New Training</i> and <i>On Your Feet</i> to aid
              and educate women.
            </h5>

            <img src={NewTraining} alt="New Training" />

            <h1 className="pb-5 text-primary">
              Shanti means <i>Peace & Calm</i>
            </h1>
          </div>
        </div>
      </Container>
    </>
  )
}
