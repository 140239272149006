import React from 'react'
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image"

export default function LotteryCommunity({ lotteryImage }) {
    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-md-4 d-flex justify-content-center">
                    </div>
                    <div className="ml-5 col-md-4 d-flex justify-content-center pb-5">
                        <GatsbyImage image={lotteryImage} alt="national lottery funding" />
                    </div>
                    <div className="ml-5 col-md-4 d-flex justify-content-center">

                    </div>
                </div>
            </Container>
        </>
    )
}
