import React from 'react'
import { Carousel } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image"

export default function CarouselMain({ choiceImage, strongImage, victImage }) {
  return (

    <Carousel>
    <Carousel.Item interval={30000}>
      <GatsbyImage image={choiceImage} alt="shanti-aid" className="carousel-image-all" />
      <Carousel.Caption>
        <h1 className="display-5 pb-10 mb-5 d-md-block">Your Choice Your Voice</h1>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item interval={30000}>
      <GatsbyImage image={strongImage} alt="shanti-aid" className="carousel-image-all" />
      <Carousel.Caption>
        <h1 className="display-5 pb-10 mb-5 d-md-block">Empowered women empower women</h1>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item interval={30000}>
      <GatsbyImage image={victImage} alt="shanti-aid" className="carousel-image-all" />
      <Carousel.Caption>
        <h1 className="display-5 pb-10 mb-5 d-md-block">You never know how strong you are, until being strong is your only choice</h1><i>Bob Marley</i>

      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  )
}
